@tailwind components;

@layer components {
    .mat-mdc-paginator {
        @apply !bg-transparent !text-base;
    }

    .mat-mdc-paginator-range-label {
        @apply !mr-5 text-primary;
    }

    .mat-mdc-paginator-range-actions > .mat-icon-button {
        @apply hover:shadow-none focus:border-red-100;
    }

    .mat-mdc-paginator-icon {
        @apply mt-[-.4rem] !h-7 !w-7 rounded-full border border-solid border-white bg-white
        p-2 text-primary shadow-2 transition-all duration-150 ease-in-out hover:border-white
            hover:bg-white hover:text-red-100 hover:shadow-2
            focus:border-grey-40 focus:outline-none focus:ring-1 focus:ring-inset focus:ring-white
            focus:hover:border-red-100;
    }

    .mat-mdc-paginator-navigation-next {
        @apply !ml-2 !mr-2;
    }

    .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
        @apply fill-white border-grey-20 bg-grey-20 shadow-none;
    }

    .mat-mdc-icon-button .mat-mdc-button-persistent-ripple {
        @apply hidden;
    }

    .mat-mdc-icon-button .mat-mdc-button-touch-target {
        @apply !left-[70%];
    }
}
