@tailwind components;

@tailwind components;

@layer components {
    .mat-datepicker-content {
        @apply !mt-2 !rounded !border-0  !shadow-3;
    }
    .mat-datepicker-content .mat-calendar {
        @apply !h-auto !font-sans;
    }
    .mat-datepicker-content .mat-calendar-abbr {
        @apply !font-semibold !no-underline;
    }
    .mat-datepicker-content .mat-calendar-body-cell {
        @apply !text-sm;
    }
    .mat-datepicker-content .mat-calendar-body-cell-content {
        @apply !rounded-none;
    }
    .mat-datepicker-content .mat-focus-indicator {
        @apply !bg-transparent;
    }
    .mat-datepicker-content
        .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
        > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
        @apply !bg-red-100 !bg-opacity-50;
    }
    .mat-datepicker-content .mat-calendar-body-selected {
        @apply !bg-red-100;
    }
    .mat-datepicker-content .mat-calendar-body-in-range {
        @apply before:border-y before:border-solid before:border-y-red-100 before:!bg-red-100 before:!bg-opacity-10;
    }
    .mat-datepicker-content .mat-calendar-body-preview-start {
        @apply !border-none before:!bg-red-100 after:!bg-red-100 hover:!border-none;
    }
    .mat-datepicker-content .mat-calendar-body-preview-end {
        @apply !border-none before:!bg-red-100 after:!bg-red-100 hover:!border-none;
    }
    .mat-datepicker-content .mat-calendar-body-in-preview {
        @apply w-[95%] before:border-y before:border-dashed before:border-y-red-100;
    }

    .mat-datepicker-content .mat-calendar-body-range-start:not(.mat-calendar-body-in-comparison-range)::before,
    .mat-datepicker-content .mat-calendar-body-range-start::after,
    .mat-datepicker-content .mat-calendar-body-comparison-start:not(.mat-calendar-body-comparison-bridge-start)::before,
    .mat-datepicker-content .mat-calendar-body-comparison-start::after,
    .mat-datepicker-content .mat-calendar-body-preview-start .mat-calendar-body-cell-preview {
        @apply !rounded-l-none border-0 border-l-0 !border-red-100 !bg-red-100 rtl:!rounded-r-none rtl:border-r-0;
    }

    .mat-datepicker-content .mat-calendar-body-range-end:not(.mat-calendar-body-in-comparison-range)::before,
    .mat-datepicker-content .mat-calendar-body-range-end::after,
    .mat-datepicker-content .mat-calendar-body-comparison-end:not(.mat-calendar-body-comparison-bridge-end)::before,
    .mat-datepicker-content .mat-calendar-body-comparison-end::after,
    .mat-datepicker-content .mat-calendar-body-preview-end .mat-calendar-body-cell-preview {
        @apply !rounded-r-none border-r-0 !border-red-100 !bg-red-100 rtl:!rounded-l-none rtl:border-l-0;
    }

    .mat-datepicker-content .mat-calendar-body-today {
        @apply !border !border-red-100;
    }

    .mat-datepicker-content .mat-calendar-arrow {
        @apply !fill-red;
    }

    .mat-datepicker-content .mat-calendar-body-label {
        @apply !opacity-0;
    }

    .mat-datepicker-content .mat-calendar-body-label[colspan='7'] {
        @apply !hidden;
    }

    .mat-datepicker-content .mat-calendar-body-cell-container button {
        @apply !rounded-none !border-none !px-1;
    }
}
