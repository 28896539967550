@tailwind components;

@layer components {
    .mat-mdc-progress-spinner {
        --mdc-circular-progress-active-indicator-color: theme('colors.red.100');
    }

    .mat-mdc-progress-spinner.mat-light {
        --mdc-circular-progress-active-indicator-color: theme('colors.white');
    }

    .mat-mdc-progress-spinner.mat-placeholder {
        --mdc-circular-progress-active-indicator-color: theme('colors.grey.30');
    }
}
