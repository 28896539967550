@tailwind components;

@layer components {
    .mat-mdc-form-field {
        @apply mt-3 h-7 w-full rounded-[.25rem] border border-solid border-grey-30 px-3 text-base text-grey-70 placeholder-grey-50 outline-none
        focus-within:!border-grey-30
        focus-within:shadow-focus hover:border-grey-80
        disabled:border-grey-30 disabled:bg-grey-10 disabled:text-grey-30 disabled:placeholder-grey-20 disabled:opacity-25 disabled:shadow-none disabled:hover:border-grey-30;
    }

    .mat-mdc-form-field-wrapper {
        @apply w-full pb-0 !important;
    }

    .mat-mdc-form-field-flex {
        @apply h-[2.4rem] items-center bg-transparent p-0 !important;
    }

    .mat-mdc-form-field-infix {
        @apply flex items-center border-t-0 py-0 !important;
    }

    .mat-mdc-form-field-suffix {
        @apply flex h-8 items-center !important;
    }

    .mat-mdc-form-field-suffix button {
        @apply focus:shadow-none focus:outline-none !important;
    }

    .mat-mdc-form-field-label-wrapper {
        @apply static mr-2 h-auto w-auto flex-grow-0 overflow-visible pt-0 !important;
    }

    .mat-mdc-form-field-label {
        @apply static top-4 -mt-4 ml-4 inline w-full overflow-visible !important;
    }

    .mat-mdc-form-field-can-float,
    .mat-mdc-form-field-should-float,
    .mat-mdc-form-field-label {
        @apply transform-none !important;
    }

    .mat-mdc-form-field-underline {
        @apply hidden !important;
    }

    .mat-mdc-form-field input,
    .mat-mdc-form-field input.ng-invalid {
        @apply !border-none font-sans text-base focus:!shadow-none;
    }

    .mat-mdc-form-field.ng-dirty.ng-invalid {
        @apply !border-error-80 focus-within:shadow-focus hover:!border-error-150;
    }
}
