@tailwind components;

@layer components {
    ul {
        list-style-type: '●';
    }

    ul li::marker {
        @apply text-red-100;
    }

    ul li::before {
        content: '';
        @apply pr-5;
    }

    ul li ul {
        list-style-type: '○';
    }

    ul li ul li::before {
        content: '';
        @apply pr-3;
    }

    ol {
        @apply list-decimal;
    }

    ol li::marker {
        @apply font-bold;
    }

    ol li::before {
        content: '';
        @apply pr-4;
    }

    ol li ol {
        @apply list-lower-alpha;
    }

    ol li ol li::before {
        content: '';
        @apply pr-3;
    }

    ol,
    ul {
        @apply list-inside;
    }

    ul li ul li {
        @apply ml-6;
    }

    ol li ol li {
        @apply ml-6;
    }

    ol li,
    ul li {
        @apply mt-4;
    }

    ol li ol li:not(:first-child),
    ul li ul li:not(:first-child) {
        @apply mt-2;
    }

    li.secondary::marker {
        color: #5c6f8a;
    }

    li.secondary {
        @apply text-grey-50;
    }
}
