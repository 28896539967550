@tailwind components;

@layer components {
    /* OLD STYLES: Cards page tabs */
    nav h6 {
        @apply inline-flex h-8 cursor-pointer items-center bg-grey-20 px-4 py-3 font-bold text-grey-50 hover:underline focus:underline
        focus:outline-none
        focus-visible:-outline-offset-2 focus-visible:outline-width-2
        md:w-auto md:min-w-[254px];
    }

    nav h6.active {
        @apply bg-white text-grey-70;
    }

    /* NEW STYLES: Configuration page tabs */
    nav .tab {
        @apply box-border whitespace-nowrap rounded-none rounded-t-lg border border-transparent p-4 font-bold text-grey-70 hover:bg-white hover:shadow-1;
    }

    nav .tab.active {
        @apply border-b-2 border-b-red-100 bg-white text-red-100 drop-shadow-md hover:border-red-100;
    }
}
