@tailwind components;

@layer components {
    mat-select + .mat-mdc-form-field-label-wrapper mat-label::after {
        content: ': ';
    }

    mat-select-trigger,
    .mat-select-placeholder,
    mat-select + .mat-mdc-form-field-label-wrapper mat-label,
    mat-select + .mat-mdc-form-field-label-wrapper mat-label::after {
        @apply whitespace-nowrap text-primary text-fill-color-initial !important;
    }

    mat-select + .mat-mdc-form-field-label-wrapper mat-label,
    mat-select + .mat-mdc-form-field-label-wrapper mat-label::after {
        @apply font-semibold !important;
    }

    .mat-select {
        max-width: calc(100% - 65px);
    }

    .mat-select-panel-wrap {
        @apply relative w-[275px] md:w-[348px] !important;
    }

    .mat-select-panel {
        @apply relative z-1000 mt-4 min-w-initial max-w-[275px] overflow-visible bg-white p-4 shadow-lg md:max-w-[348px] !important;
    }

    .mat-select-panel::after {
        content: '\A';
        @apply absolute -top-2 right-4 text-white;
    }

    .mat-select-arrow-wrapper {
        @apply hidden !important;
    }

    /* allow cdk-overlay-origin to be align with select */
    .mat-select-trigger {
        @apply w-full;
        transform: translateX(calc(100% - 275px)) translateY(theme('spacing.6'));
    }

    .mat-select-value {
        @apply relative w-full;
        transform: translateX(calc(275px - 100%)) translateY(calc(theme('spacing.6') * -1));
    }

    @screen md {
        .mat-select-trigger {
            transform: translateX(calc(100% - 348px)) translateY(theme('spacing.6'));
        }

        .mat-select-value {
            transform: translateX(calc(348px - 100%)) translateY(calc(theme('spacing.6') * -1));
        }
    }
}
