@tailwind base;

@layer base {
    @font-face {
        font-display: swap;
        font-family: 'Edenred';
        font-weight: 700;
        src: url('../fonts/edenred_bold.woff2') format('woff2');
    }

    @font-face {
        font-display: swap;
        font-family: 'Edenred';
        src: url('../fonts/edenred_regular.woff2') format('woff2');
    }

    @font-face {
        font-display: swap;
        font-family: 'Ubuntu';
        font-weight: 700;
        src: url('../fonts/ubuntu_bold.woff2') format('woff2');
    }

    @font-face {
        font-display: swap;
        font-family: 'Ubuntu';
        font-weight: 500;
        src: url('../fonts/ubuntu_medium.woff2') format('woff2');
    }

    @font-face {
        font-display: swap;
        font-family: 'Ubuntu';
        src: url('../fonts/ubuntu_regular.woff2') format('woff2');
    }

    :root {
        -webkit-tap-highlight-color: transparent;
    }

    html,
    body {
        @apply min-h-[100vh] text-grey-70;
    }

    h1 {
        @apply my-5 inline-block font-edenred text-6xl text-primary md:text-8xl;
    }

    h2 {
        @apply my-3 inline-block font-edenred text-5xl text-primary md:text-7xl;
    }

    h3 {
        @apply mt-3 mb-2 inline-block font-edenred text-4xl text-grey-80 md:text-6xl;
    }

    h4 {
        @apply my-3 inline-block font-edenred text-2xl  md:text-3xl;
    }

    h5 {
        @apply mt-4 inline-block font-edenred text-lg font-bold  md:text-xl;
    }

    h6 {
        @apply mt-4 inline-block text-base font-bold;
    }

    h4,
    h5,
    h6 {
        @apply text-grey-70;
    }

    button {
        @apply flex max-h-8 min-h-7 w-full items-center justify-center rounded-3xl border border-primary px-6 py-3 text-sm font-medium hover:cursor-pointer focus:outline-none hover:disabled:cursor-default md:w-auto md:text-lg;
    }

    *:is(input, select, textarea).read-only {
        @apply border-grey-30 bg-grey-10 text-grey-30 placeholder-grey-20 shadow-none hover:border-grey-30 cursor-default pointer-events-none;
    }

    input,
    select,
    textarea {
        @apply mt-3 h-7 w-full rounded-[.25rem] border border-solid border-grey-30 px-3 text-base text-grey-70 placeholder-grey-30 outline-none
        hover:border-grey-80
        focus:border-grey-30 focus:shadow-focus
        read-only:border-grey-30 read-only:bg-grey-10 read-only:text-grey-30 read-only:placeholder-grey-20 read-only:shadow-none read-only:hover:border-grey-30 read-only:cursor-default
        disabled:border-grey-30 disabled:bg-grey-10 disabled:text-grey-30 disabled:placeholder-grey-20 disabled:shadow-none disabled:hover:border-grey-30;
    }

    select {
        @apply appearance-none;
    }

    textarea {
        @apply min-h-[9.375rem] p-4;
    }

    a {
        @apply underline;
    }

    a.read-only {
        @apply pointer-events-none;
    }

    a.disabled {
        @apply opacity-50;
    }

    p {
        @apply mt-4 text-sm md:text-base;
    }

    form {
        @apply w-full;
    }

    .mat-drawer-backdrop {
        @apply fixed !important;
    }

    .mat-menu-panel {
        @apply !min-w-0;
    }

    .mat-icon {
        @apply !overflow-visible !opacity-100;
        font-variation-settings: 'FILL' 1;
    }
}
