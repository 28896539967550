.mat-button-toggle {
    @apply font-sans text-xs font-medium text-primary;
}

.mat-button-toggle .mat-button-toggle-label-content {
    @apply leading-none;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
    @apply !border-grey-20;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
    @apply !border-l-grey-20;
}

.mat-button-toggle-appearance-standard.mat-button-toggle {
    @apply hover:bg-grey-10;
}

.mat-button-toggle-appearance-standard.mat-button-toggle-checked {
    @apply bg-grey-20 text-grey-70 hover:!bg-grey-20 hover:!bg-opacity-80;
}
