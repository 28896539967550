@tailwind components;

@layer components {
    mat-radio-group {
        @apply relative inline-flex flex-col;
    }

    mat-radio-button {
        @apply mt-2;
    }

    mat-radio-button .mdc-form-field {
        @apply flex;
    }

    .mat-mdc-radio-button .mdc-radio {
        @apply pl-0;
        --mdc-radio-state-layer-size: 18px !important;
    }

    .mdc-radio__outer-circle {
        @apply border !important;
    }

    .mdc-radio__background {
        @apply scale-[.85];
    }

    .mat-mdc-radio-button {
        --mat-mdc-radio-ripple-color: transparent !important;
        --mat-mdc-radio-checked-ripple-color: transparent !important;
        --mdc-radio-selected-focus-icon-color: theme('colors.primary') !important;
        --mdc-radio-selected-hover-icon-color: theme('colors.primary') !important;
        --mdc-radio-selected-icon-color: theme('colors.primary') !important;
        --mdc-radio-selected-pressed-icon-color: theme('colors.primary') !important;
        --mat-radio-checked-ripple-color: theme('colors.primary') !important;
        --mdc-radio-unselected-icon-color: theme('colors.grey.70') !important;
    }

    mat-radio-button label {
        @apply ml-1 w-full cursor-pointer font-sans text-sm text-grey-50 md:text-base !important;
    }

    .mat-mdc-radio-checked label {
        @apply text-primary !important;
    }

    .mat-mdc-radio-button.accent-radio {
        --mdc-radio-selected-icon-color: theme('colors.mint.100') !important;
        --mat-radio-checked-ripple-color: theme('colors.mint.100') !important;
        --mdc-radio-unselected-icon-color: theme('colors.grey.70') !important;
        --mdc-radio-selected-hover-icon-color: theme('colors.mint.100') !important;
    }

}
