.mat-mdc-snack-bar-container {
    --mdc-snackbar-container-color: tranparent !important;
    --mdc-snackbar-supporting-text-color: theme('colors.grey.70');
}

.mdc-snackbar__surface,
.mdc-snackbar__label {
    @apply p-0 shadow-none !important;
}

.mat-snack-bar-handset {
    @apply !pointer-events-none;
}

er-message.md\:pt-0 > div {
    @apply !pt-0;
}
