@tailwind components;

@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

@import './components/overlay-pane.component.css';
@import './components/form-field.component.css';
@import './components/list.component.css';
@import './components/loader.component.css';
@import './components/option.component.css';
@import './components/pagination.component.css';
@import './components/radio.component.css';
@import './components/select.component.css';
@import './components/tooltip.component.css';
@import './components/table.component.css';
@import './components/calendar.component.css';
@import './components/slide-toggle.component.css';
@import './components/button-toggle.component.css';
@import './components/nav.component.css';
@import './components/accordion.component.css';
@import './components/alert.component.css';

@layer components {
    p.small {
        @apply text-xs md:text-sm;
    }

    p.large {
        @apply my-4 text-lg md:text-xl;
    }

    input.ng-dirty.ng-invalid,
    select.ng-dirty.ng-invalid {
        @apply border-error-80 hover:border-error-150 focus:shadow-focus;
    }

    button.primary {
        @apply bg-primary text-white shadow-2 transition-all duration-150 ease-in-out
        hover:bg-grey-70 hover:shadow-2

        focus:border-grey-40 focus:bg-primary focus:ring-1 focus:ring-inset focus:ring-white
        focus:hover:bg-grey-70

        disabled:border-grey-20 disabled:bg-grey-20 disabled:text-white disabled:shadow-none
        hover:disabled:bg-grey-20 disabled:hover:text-white hover:disabled:shadow-none;
    }

    button.secondary {
        @apply border-white bg-white text-primary shadow-2 transition-all duration-150 ease-in-out
        hover:!bg-white hover:text-red-100 hover:shadow-2
        focus:border-primary focus:bg-white focus:shadow-none
        focus:hover:border-red-100 focus:hover:shadow-focus
        disabled:border-grey-20 disabled:bg-grey-20 disabled:!text-grey-10 disabled:shadow-none hover:disabled:!bg-grey-20 disabled:hover:text-grey-10 hover:disabled:!shadow-none;
    }

    button.tertiary {
        @apply border-grey-50 bg-transparent text-grey-60 transition-all duration-150 ease-in-out
        hover:border-red-100 hover:!bg-transparent hover:text-red-100
        focus:border-grey-40 focus:bg-transparent focus:ring-1 focus:ring-inset focus:ring-grey-60 focus:ring-offset-1 focus:ring-offset-white
        focus:hover:border-grey-40 focus:hover:bg-transparent focus:hover:shadow-none focus:hover:ring-1 focus:hover:ring-inset focus:hover:ring-red-100 focus:hover:ring-offset-1 focus:hover:ring-offset-white
        disabled:border-grey-30 disabled:bg-transparent disabled:text-grey-30 hover:disabled:bg-grey-20 disabled:hover:text-grey-30;
    }

    button.ghost,
    a.ghost {
        @apply rounded-full border border-transparent px-6 py-2 text-sm font-medium uppercase text-primary no-underline shadow-none transition-all duration-150 ease-in-out
        hover:text-red-100
        focus:border-grey-70 focus:text-grey-70
        disabled:!text-grey-30;
    }

    button.secondary.destructive {
        @apply text-error-100 hover:text-error-100 focus:border-error-100 hover:focus:border-error-150 hover:focus:text-error-150;
    }

    button.secondary.warning {
        @apply text-warning-100 hover:text-warning-100 focus:border-warning-100 hover:focus:border-warning-150 hover:focus:text-warning-150;
    }

    button.tertiary,
    button.ghost {
        @apply max-h-7 px-5 py-2 text-sm md:text-base;
    }

    button.clear-button {
        @apply m-0 rounded-none border-0 bg-transparent p-0 text-sm font-normal text-primary underline hover:border-0 hover:text-red-100 focus:border-0 focus-visible:outline focus-visible:outline-1 focus-visible:outline-grey-80 disabled:text-grey-50 disabled:no-underline;
    }

    button.mat-icon-button {
        @apply flex items-center justify-center border-none p-0 hover:text-red-100 hover:focus:border-red-100;
    }

    button.mat-icon-button.cdk-focused {
        @apply border border-solid border-grey-70 outline-none;
    }

    button.mat-icon-button .mat-button-focus-overlay {
        @apply !opacity-0;
    }
}
