.mat-mdc-tooltip {
    @apply relative ml-2 mt-3 block h-auto w-auto rounded-md bg-primary p-3 !important;
}

.mdc-tooltip__surface {
    @apply !bg-transparent !text-sm;
}

button.er-tooltip {
    @apply border border-solid border-primary text-primary
    ring-2 ring-inset ring-primary ring-offset-primary
    focus:border-grey-40 focus:ring-2 focus:ring-offset-white
    !important;
}
