.cdk-table {
    @apply table min-w-full border-collapse overflow-hidden rounded-md rounded-bl-none rounded-br-none bg-white drop-shadow-md;
}

.cdk-table.condensed {
    @apply rounded-none text-sm drop-shadow-none;
}

.cdk-table caption {
    @apply h-auto bg-white px-5 py-4 text-left;
}

.cdk-table caption *[title] {
    @apply mr-4 whitespace-nowrap text-xl font-medium;
}

.cdk-footer-row,
.cdk-header-row {
    @apply relative table-row border-t border-dashed border-grey-20 bg-grey-10 shadow-md;
}

.cdk-row.no-hover {
    @apply md:hover:bg-white;
}

.cdk-row {
    @apply table-row border-b border-l-4 border-b-grey-20 border-l-transparent md:border-l-0 md:hover:bg-grey-10;
}

.cdk-row:where(.expanded-trigger, .expanded) {
    @apply border-l-red-100 bg-grey-10 md:border-l-0 md:bg-white;
}

.cdk-row.expanded-trigger {
    @apply border-b-0 md:border-b;
}

.cdk-table.condensed .cdk-row {
    @apply border-b-0;
}

.cdk-row:last-child {
    @apply border-b-0;
}

.cdk-no-data-row td {
    @apply h-[300px] p-4 text-center;
}

.cdk-cell {
    @apply table-cell p-4 align-middle;
}

.cdk-table.condensed .cdk-cell {
    @apply py-3 md:py-2;
}

.cdk-header-cell {
    @apply table-cell h-[56px] cursor-pointer select-none p-4 text-left align-middle text-sm font-medium uppercase text-grey-50;
}

.mat-sort-header-arrow {
    @apply !translate-y-0 !opacity-100;
}

.mat-sort-header-arrow::before {
    @apply absolute top-1 inline-block h-0 w-0 rounded-md border-b-[6px] border-l-[6px] border-r-[6px] border-b-primary border-l-transparent  border-r-transparent transition-[rotate] content-[''];
}

[aria-sort='descending'] .mat-sort-header-arrow::before {
    @apply rotate-180;
}

.mat-sort-header-indicator,
.mat-sort-header-stem {
    @apply !hidden;
}
