@tailwind components;

@layer components {
    .mat-mdc-slide-toggle {
        --mdc-switch-handle-elevation: none !important;
        --mdc-switch-track-height: 24px !important;
        --mdc-switch-track-width: 40px !important;
        --mdc-switch-track-shape: 12px !important;
        --mdc-switch-selected-icon-size: 20px !important;
        --mdc-switch-state-layer-size: 0px !important;
        --mdc-switch-selected-icon-color: theme('colors.red.100') !important;
        --mdc-switch-selected-handle-color: theme('colors.white') !important;
        --mdc-switch-selected-pressed-handle-color: theme('colors.white') !important;
        --mdc-switch-selected-hover-handle-color: theme('colors.white') !important;
        --mdc-switch-selected-focus-state-layer-color: theme('colors.red.100') !important;
        --mdc-switch-selected-hover-state-layer-color: theme('colors.red.100') !important;
        --mdc-switch-selected-pressed-state-layer-color: theme('colors.red.100') !important;
        --mdc-switch-selected-focus-handle-color: theme('colors.white') !important;
        --mdc-switch-selected-focus-track-color: theme('colors.red.100') !important;
        --mdc-switch-selected-hover-track-color: theme('colors.red.100') !important;
        --mdc-switch-selected-track-color: theme('colors.red.100') !important;
        --mdc-switch-selected-pressed-track-color: theme('colors.red.100') !important;

        --mdc-switch-unselected-icon-color: theme('colors.white') !important;
        --mdc-switch-unselected-handle-color: theme('colors.white') !important;
        --mdc-switch-unselected-hover-handle-color: theme('colors.white') !important;
        --mdc-switch-unselected-focus-handle-color: theme('colors.white') !important;
        --mdc-switch-unselected-pressed-handle-color: theme('colors.white') !important;
        --mdc-switch-unselected-track-color: theme('colors.primary') !important;
        --mdc-switch-unselected-focus-track-color: theme('colors.primary') !important;
        --mdc-switch-unselected-hover-track-color: theme('colors.primary') !important;
        --mdc-switch-unselected-pressed-track-color: theme('colors.primary') !important;
    }

    .mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle-track {
        width: calc(100% - var(--mdc-switch-handle-width, 20px) - 4px) !important;
        @apply left-[2px];
    }
}
