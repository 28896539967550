@tailwind components;

@layer components {
    .cdk-overlay-connected-position-bounding-box {
        @apply absolute z-1000 flex flex-col;
    }

    .cdk-overlay-container,
    .cdk-global-overlay-wrapper {
        @apply pointer-events-none top-0 left-0 h-full w-full;
    }

    .cdk-overlay-container {
        @apply fixed z-1000;
    }

    .cdk-overlay-container:empty {
        @apply hidden;
    }

    .cdk-global-overlay-wrapper {
        @apply absolute z-1000 flex;
    }

    .cdk-global-overlay-wrapper:has(> .mat-snack-bar-handset) {
        @apply !z-1100;
    }

    .cdk-overlay-pane {
        @apply pointer-events-auto absolute z-1000 box-border flex max-h-full max-w-full;
    }
}
