@tailwind components;

@layer components {
    .mat-option {
        @apply mt-4 h-5 bg-white px-0 font-sans text-primary  !important;
    }

    .mat-option:first-child {
        @apply mt-0 !important;
    }

    .mat-pseudo-checkbox {
        @apply relative h-5 w-5 border-solid border-primary  bg-white !important;
    }

    .mat-pseudo-checkbox::after {
        @apply border-none text-primary  !important;
    }
}
