mat-expansion-panel-header .mat-expansion-indicator {
    @apply mr-2 h-3 w-3;
}

mat-expansion-panel-header .mat-expansion-indicator::after {
    @apply mt-[-12px] ml-[2px] text-primary;
}

mat-expansion-panel .mat-expansion-panel-body {
    @apply p-0;
}

.mat-expansion-panel-content {
    @apply font-sans text-base;
}

.mat-expansion-panel-header.mat-expanded {
    @apply h-8;
}
